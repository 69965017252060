.App {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
}

.App-header {
  width: 1000%;
  height: 100px;
  background-color: rgb(121, 192, 159);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.App-header h1 {
  font-size: 24px;
  margin-bottom: 20px;
  outline: none;
  cursor: text;
  min-height: 1em;
  overflow: hidden;
  white-space: nowrap;
}
