body, html, #root {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #07bdae; */
  --primary-light-color: #e3f2fd;
  --secondary-light-color: #90caf9;
  --primary-dark-color: #42a5f5;
  --secondary-dark-color: #1976d2;

  --font-primary-light-color: #e0f1ff;
  --font-secondary-light-color: #64b5f6;
  --font-primary-dark-color: #0f519c;
  --font-secondary-dark-color: #07306e;

  --font-grey-lighter-color: #dcdcdc;
  --font-grey-light-color: #bcbaba;
  --font-grey-medium-color: #878787;
  --font-grey-dark-color: #707070;

  --linear-gradient: linear-gradient(to right bottom, white, var(--secondary-light-color));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
