.formBuilder {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 1%;
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
  height: min-content;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 20px;
}

@media (max-width: 1200px) {
  .formBuilder {
    width: 80%;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .formBuilder {
    width: 95%;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .formBuilder {
    width: 100%;
    padding: 15px;
  }
}
